@import 'styles/theme.css';
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
.disable-selection {
    -moz-user-select: none!important; /* Firefox */
    -ms-user-select: none!important; /* Internet Explorer */
    -khtml-user-select: none!important; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none!important; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none!important; /* Disable Android and iOS callouts*/
}
ant-checkbox-checked::after{
    border: 1px solid white;
}

.full-width{
    width: 100%;
}
